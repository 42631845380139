import React from 'react';
import { useEffect, useRef, useState } from 'react';
import '../App.css';
import { Alert, AppBar, Autocomplete, Avatar, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CardMedia, Checkbox, Chip, CircularProgress, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, Link, List, ListItem, ListItemAvatar, ListItemText, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Slide, Slider, Snackbar, Stack, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, TextField, ThemeProvider, Toolbar, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomeIcon from '@mui/icons-material/Home';
import { useParams } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import StorageIcon from '@mui/icons-material/Storage';
import FolderIcon from '@mui/icons-material/Folder';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import CameraIcon from '@mui/icons-material/Camera';
import DiskSize from '../components/DiskSize';
import DownloadIcon from '@mui/icons-material/Download';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import UploadIcon from '@mui/icons-material/Upload';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import StarIcon from '@mui/icons-material/Star';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import MoodIcon from '@mui/icons-material/Mood';
import TagIcon from '@mui/icons-material/Tag';
import DeleteForever from '@mui/icons-material/DeleteForever';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { TransitionProps } from '@mui/material/transitions';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import ShareIcon from '@mui/icons-material/Share';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import useMediaQuery from '@mui/material/useMediaQuery';
import PlaceIcon from '@mui/icons-material/Place';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import TimeHHMMSS from '../components/TimeHHMMSS';
import WarningIcon from '@mui/icons-material/Warning';
import InputMask from "react-input-mask";
import EventIcon from '@mui/icons-material/Event';
import Navigator from '../components/Nav';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WavesurferPlayer from '../components/WavesurferPlayer';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const APIURL = process.env.REACT_APP_APIURL;

const pouser = localStorage.getItem('pouser');
const pouserid = localStorage.getItem('pouserid');
const pomod = localStorage.getItem('pomod');
const poadmin = localStorage.getItem('poadmin');
const potoken = localStorage.getItem('potoken');

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(1),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

const acicon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const accheckedIcon = <CheckBoxIcon fontSize="small" />;


const MaskedTextField = (props: any) => {
    const { mask, ...otherProps } = props;

    return (
        <InputMask mask={mask} {...otherProps}>
            {(inputProps: any) => <TextField {...inputProps} />}
        </InputMask>
    );
};


function EventFile() {
    const { filehash } = useParams();
    const [isLoading, setisLoading] = useState(true);
    const [isLoadingLogs, setisLoadingLogs] = useState(true);
    const [isComplete, setisComplete] = useState(false);
    const [modalLinkFile, setmodalLinkFile] = useState(false);
    const [modalEditFile, setmodalEditFile] = useState(false);
    const [modalFilePublishClip, setmodalFilePublishClip] = useState(false);
    const [modalFileLogEdit, setmodalFileLogEdit] = useState(false);
    const [modalFileLogClip, setmodalFileLogClip] = useState(false);
    const [completeDisabled, setcompleteDisabled] = useState(false);
    const [modalFileAddLogUsers, setmodalFileAddLogUsers] = useState(false);
    const [modalFileAddPeople, setmodalFileAddPeople] = useState(false);
    const [modalFileAddContacts, setmodalFileAddContacts] = useState(false);
    const [modalFileAddLogTags, setmodalFileAddLogTags] = useState(false);
    const [modalFileAddArea, setmodalFileAddArea] = useState(false);
    const [modalFileAddLogArea, setmodalFileAddLogArea] = useState(false);
    const [event, setEvent] = useState<any[]>([]);
    const [locationareas, setlocationareas] = useState<any[]>([]);
    const [linkeventfiles, setlinkeventfiles] = useState<any[]>([]);
    const [eventid, seteventid] = useState(0);
    const [eventhash, seteventhash] = useState("");
    const [eventfile, setEventFile] = useState<any[]>([]);
    const [eventfilelogs, setEventFileLogs] = useState<any[]>([]);
    const [eventinvestigators, seteventinvestigators] = useState<any[]>([]);
    const [logCategories, setlogCategories] = useState<any[]>([]);
    const [logTags, setlogTags] = useState<any[]>([]);
    const [logUsers, setlogUsers] = useState<any[]>([]);
    const [pousers, setpousers] = useState<any[]>([]);
    const [revuser, setrevuser] = useState(0);
    const [LinkFile, setLinkFile] = useState(0);
    const [revuserid, setrevuserid] = useState(0);
    const [logAreas, setlogAreas] = useState<any[]>([]);
    const [SelectedLogUsers, setSelectedLogUsers] = useState<any[]>([]);
    const [SelectedLinkedFiles, setSelectedLinkedFiles] = useState<any[]>([]);
    const [personName, setPersonName] = useState<string[]>([]);
    const [eventfilecategories, seteventfilecategories] = useState<any[]>([]);
    const [activelogid, setactivelogid] = useState(0);
    const [activelogindexid, setactivelogindexid] = useState(0);
    const [ClipDescription, setClipDescription] = useState('');
    const [ClipPublicDescription, setClipPublicDescription] = useState('');
    const [contacts, setContacts] = useState<any[]>([]);
    const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
    const [snackSuccessMessage, setSnackSuccessMessage] = useState('');
    const [eventfiletype, seteventfiletype] = useState("");


    const [clipVolume, setClipVolume] = React.useState<number>(0);

    const handleChangeClipVolume = (event: Event, newValue: number | number[]) => {
        setClipVolume(newValue as number);
    };


    const handleCloseSnackSuccess = () => {
        setOpenSnackSuccess(false);
    };


    function getcontacts() {
        axios.get(APIURL + "/v2/optionscontacts", { withCredentials: true })
            .then((response: any) => {
                setContacts(response.data);
            })
    }


    const [logtsvalue, setlogtsvalue] = useState('');

    const drawerWidth = 256;
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('xl'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const [selectedTags, setSelectedTags] = useState<any[]>([]);
    const [SelectedLogAreas, setSelectedLogAreas] = useState<any[]>([]);
    const [SelectedFileAreas, setSelectedFileAreas] = useState<any[]>([]);
    const [SelectedFileUsers, setSelectedFileUsers] = useState<any[]>([]);
    const [SelectedFileContacts, setSelectedFileContacts] = useState<any[]>([]);

    const [eflclippublicstate, seteflclippublicstate] = useState(false);
    const [cliphash, setClipHash] = useState('');
    const [clipfile, setClipFile] = useState('');
    const [clipeqname, setClipEqname] = useState('');
    const [clipisvideo, setClipIsVideo] = useState(true);
    const [modalViewClip, setmodalViewClip] = useState(false);
    const [modalShareClip, setmodalShareClip] = useState(false);
    function handleClickOpen(chash: any, trname: any, effilename: any) {
        setClipHash(chash);
        setClipFile(effilename);
        setClipEqname(trname);
        if (eventfile[0]?.trtypeid === 2) {
            setClipIsVideo(false);
        }
        else {
            setClipIsVideo(true);
        }
        setmodalViewClip(true);
    };
    const handleCloseModalViewClip = () => {
        setmodalViewClip(false);
    };
    function handleOpenModalShareClip(chash: any, trtypeid: any, trname: any, effilename: any) {
        setClipHash(chash);
        setClipFile(effilename);
        setClipEqname(trname);
        if (trtypeid === 2) {
            setClipIsVideo(false);
        }
        else {
            setClipIsVideo(true);
        }
        setmodalShareClip(true);
    };
    const handleCloseModalShareClip = () => {
        setmodalShareClip(false);
    };


    const handleModalEditFileClose = () => {
        setmodalEditFile(false);
    };
    const handleModalEditFileOpen = () => {
        geteventfilecategories();
        getevent();
        setmodalEditFile(true);
    };

    const handleCloseModalLinkFile = () => {
        setmodalLinkFile(false);
    };
    const handleOpenModalLinkFile = () => {
        getlinkeventfiles();
        setmodalLinkFile(true);
    };

    const handleSelectLinkFile = (event: any, values: any) => {
        setLinkFile(values['id']);
    }



    const gotoEventFileFolder = (folderid: string) => {
        localStorage.setItem('eventtab', "1");
        localStorage.setItem('currentfilefolder' + eventid, folderid.toString());
        // window.location.assign("/event/" + eventfile[0]?.efeventhash);
    }

    const gotoEvent = () => {
        localStorage.setItem('eventtab', "0");
    }

    const handleOpenModalFileClipPublish = (logid: number, logpublic: number, efldescription: any, efldescriptionpublic: any) => {
        setactivelogid(logid);
        setClipDescription(efldescription);
        setClipPublicDescription(efldescriptionpublic);
        if (logpublic === 1) {
            seteflclippublicstate(true);
        }
        else {
            seteflclippublicstate(false);
        }
        setmodalFilePublishClip(true);
    };
    const handleCloseModalFileClipPublish = () => {
        setmodalFilePublishClip(false);
    };


    const handleOpenLogAddUsers = (logid: number) => {
        setactivelogid(logid);
        setmodalFileAddLogUsers(true);
    };
    const handleCloseLogAddUsers = () => {
        setmodalFileAddLogUsers(false);
    };

    const handleOpenmodalFileLogClip = (logid: number, indexid: number) => {
        setactivelogid(logid);
        setactivelogindexid(indexid);
        setmodalFileLogClip(true);
    };
    const handleClosemodalFileLogClip = () => {
        setmodalFileLogClip(false);
    };

    const handleOpenmodalFileLogEdit = (logid: number, indexid: number) => {
        setactivelogid(logid);
        setactivelogindexid(indexid);
        setmodalFileLogEdit(true);
    };
    const handleClosemodalFileLogEdit = () => {
        setmodalFileLogEdit(false);
    };



    const handleOpenmodalFileLogAddTags = (logid: number) => {
        getlogtags();
        setactivelogid(logid);
        setmodalFileAddLogTags(true);
    };
    const handleClosemodalFileLogAddTags = () => {
        setmodalFileAddLogTags(false);
    };


    const handleOpenmodalFileAddPeople = () => {
        getevent();
        setmodalFileAddPeople(true);
    };
    const handleClosemodalFileAddPeople = () => {
        setmodalFileAddPeople(false);
    };

    const handleOpenmodalFileAddContact = () => {
        getcontacts();
        setmodalFileAddContacts(true);
    };
    const handleClosemodalFileAddContact = () => {
        setmodalFileAddContacts(false);
    };

    const handleOpenmodalFileLogAddArea = (logid: number) => {
        setactivelogid(logid);
        setmodalFileAddLogArea(true);
    };
    const handleClosemodalFileLogAddArea = () => {
        setmodalFileAddLogArea(false);
    };

    const handleOpenmodalFileAddArea = () => {
        getlocationareas();
        setmodalFileAddArea(true);
    };
    const handleClosemodalFileAddArea = () => {
        setmodalFileAddArea(false);
    };


    function getlocationareas() {
        setisLoading(true);
        axios.get(APIURL + "/areas/" + eventfile[0]?.eflocationid, { withCredentials: true })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setlocationareas(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }



    const handleModSelectReviewUser = (event: any, values: any) => {
        setrevuserid(values['id']);
        setrevuser(event['name']);
    }


    function geteventfilecategories() {
        setisLoading(true);
        axios.get(APIURL + "/eventfilecategories", { withCredentials: true })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    seteventfilecategories(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    function getlogtags() {
        setisLoading(true);
        axios.get(APIURL + "/eventfilelogtags", { withCredentials: true })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setlogTags(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    function getevent() {
        setisLoading(true);
        axios.get(APIURL + "/eventdetails/" + eventfile[0]?.efeventhash, { withCredentials: true })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setEvent(response.data);
                    seteventid(response.data[0].eventid);
                    seteventinvestigators(response.data[0].investigators);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    async function geteventfile() {
        setisLoading(true);
        await axios.get(APIURL + "/v3/eventfilehash/" + filehash, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventFile(response.data);
                seteventid(response.data[0].efeventid);
                setlogUsers(response.data[0].investigators);
                setlogAreas(response.data[0].areas);
                setrevuser(response.data[0].efreviewusername);
                setrevuserid(response.data[0].efreviewuserid);
                if (response.data[0].efreviewstatusid === 2) {
                    setisComplete(true);
                }
                if (response.data[0].effiletypeid === 1) {
                    seteventfiletype("video");
                }
                else if (response.data[0].effiletypeid === 2) {
                    seteventfiletype("audio");
                }
                else if (response.data[0].effiletypeid === 3) {
                    seteventfiletype("cctv");
                }
            })
        getlogcategories();
        setisLoading(false);
    }

    async function geteventfilelogs() {
        setisLoadingLogs(true);
        await axios.get(APIURL + "/eventfilelogshash/" + filehash, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setEventFileLogs(response.data);
            })
        setisLoadingLogs(false);
    }

    function getlogcategories() {
        axios.get(APIURL + "/eventfilelogcategories/", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setlogCategories(response.data);
            })
    }

    function getpousers() {
        axios.get(APIURL + "/v2/optionsusers", { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setpousers(response.data);
            })
    }

    async function submitDateSync() {
        var formdata = {};
        await axios.post(APIURL + "/v2/eventfiledatesync/" + eventfile[0]?.efid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Dato/Tid på filen er synkronisert mot oppdraget.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }


    const submitNewLog = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            efltime: data.get('logts'),
            efllogcategoryid: data.get('logcategory'),
            efldescription: data.get('logdescription'),
            efleventid: eventfile[0]?.efeventid,
            efllocationid: eventfile[0]?.eflocationid,
        };
        setlogtsvalue('');
        event.currentTarget.reset();
        event.currentTarget.logts.focus();
        let efllogcategoryid = data.get('logcategory');
        ((efllogcategoryid == "1") || (efllogcategoryid == "2") || (efllogcategoryid == "5")) &&
            setcompleteDisabled(true);
        await axios.post(APIURL + "/eventfilelog/" + eventfile[0]?.efid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    geteventfilelogs();
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }

    const submitNewClip = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalFileLogClip(false);
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            eflclipts: data.get('eflclipts'),
            eflcliptslen: data.get('eflcliptslen'),
            eflclipvolume: clipVolume,
            eflid: activelogid,
            token: potoken
        };
        var eflclipts = data.get('eflclipts');
        var eflcliptslen = data.get('eflcliptslen');
        await axios.post("https://pofs01.paraoffice.com/makeclip.php", formdata, { withCredentials: false, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    geteventfilelogs();
                    setSnackSuccessMessage("Nytt klipp på " + eflcliptslen + " sekunder er generert fra tidspunkt " + eflclipts + " volum nivå: " + clipVolume + ".");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }



    const submitFileAddPeople = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalFileAddPeople(false);
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            investigators: SelectedFileUsers
        };
        axios.post(APIURL + "/eventfileinvestigators/" + eventfile[0]?.efid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Person(er) involvert lagt til på filen.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        event.currentTarget.reset();
        setisLoading(false);
    }

    const submitFileAddContacts = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalFileAddContacts(false);
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            contacts: SelectedFileContacts
        };
        axios.post(APIURL + "/v3/eventfilecontacts/" + eventfile[0]?.efid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Kontaktperson(er) lagt til på filen.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        event.currentTarget.reset();
        setisLoading(false);
    }

    const submitFileAddTags = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalFileAddLogTags(false);
        setisLoading(true);
        var formdata = {
            tags: selectedTags
        };
        axios.post(APIURL + "/eventfilelogtags/" + activelogid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfilelogs();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        event.currentTarget.reset();
        setisLoading(false);
    }

    const submitFileAddArea = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalFileAddArea(false);
        setisLoading(true);
        var formdata = {
            areas: SelectedFileAreas
        };
        axios.post(APIURL + "/eventfileareas/" + eventfile[0]?.efid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Områd(er) lagt til på filen.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        event.currentTarget.reset();
        setisLoading(false);
    }


    const submitFileLink = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalLinkFile(false);
        setisLoading(true);
        var formdata = {
            linkfileid: SelectedLinkedFiles
        };
        axios.post(APIURL + "/eventfilelink/" + eventfile[0]?.efid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Link mot filer lagt til.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        event.currentTarget.reset();
        setisLoading(false);
    }




    const submitFileUnLink = (LinkFile: any) => {
        setisLoading(true);
        var formdata = {
            linkfileid: LinkFile
        };
        axios.post(APIURL + "/eventfileunlink/" + eventfile[0]?.efid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Fjernet link mot fil.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }






    function getlinkeventfiles() {
        axios.get(APIURL + "/eventfile/linkfilelist/" + eventid, { withCredentials: true })
            .then((response: any) => {
                setlinkeventfiles(response.data);
            })
    }


    const submitShareClip = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        setmodalShareClip(false);
        var data = new FormData(event.currentTarget);
        var formdata = {
            dschan: data.get('dschan'),
            eflhash: cliphash,
            pousername: pouser,
            potoken: potoken
        };
        axios.post("https://pofs01.paraoffice.com/clip-discord.php", formdata, { withCredentials: false, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setSnackSuccessMessage("Deler klipp på Discord.");
                    setOpenSnackSuccess(true);
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const submitFileLogAddArea = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalFileAddLogArea(false);
        setisLoading(true);
        var formdata = {
            areas: SelectedLogAreas
        };
        axios.post(APIURL + "/eventfilelogareas/" + activelogid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfilelogs();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        event.currentTarget.reset();
        setisLoading(false);
    }
    const submitFileLogAddUsers = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalFileAddLogUsers(false);
        setisLoading(true);
        var formdata = {
            investigators: SelectedLogUsers
        };
        axios.post(APIURL + "/eventfileloginvestigators/" + activelogid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfilelogs();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        event.currentTarget.reset();
        setisLoading(false);
    }

    const submitPublishClip = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalFilePublishClip(false);
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            efldescriptionpublic: data.get('efldescriptionpublic'),
            eflclippublic: data.get('eflclippublic'),
        };
        axios.post(APIURL + "/eventfilelogpublish/" + activelogid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfilelogs();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        event.currentTarget.reset();
        setisLoading(false);
    }


    const submitFileEdit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalEditFile(false);
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            effiledescription: data.get('effiledescription'),
            eventfiledate: data.get('eventfiledate'),
            eventfiletime: data.get('eventfiletime'),
            efcategoryid: data.get('efcategoryid'),
            effilelength: data.get('effilelength'),
            efrecuser: data.get('efrecuser'),
            effiletsac: data.get('effiletsac'),
            efreviewuserid: revuserid
        };
        axios.put(APIURL + "/eventfileinfo/" + eventfile[0]?.efid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Filen er oppdatert med ny informasjon.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        event.currentTarget.reset();
        setisLoading(false);
    }

    const submitFileLogEdit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setmodalFileLogEdit(false);
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            efldescription: data.get('efldescription'),
            efltime: data.get('efltime'),
            efllogcategoryid: data.get('efllogcategoryid')
        };
        axios.put(APIURL + "/eventfilelog/" + activelogid, formdata, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfilelogs();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        event.currentTarget.reset();
        setisLoading(false);
    }


    const submitWipeFileUser = (userid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/eventfileinvestigators/" + eventfile[0].efid + "/" + userid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Fjernet person involvert på filen.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const submitWipeFileContact = (contactid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/v3/eventfilecontact/" + eventfile[0].efid + "/" + contactid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Fjernet kontaktperson på filen.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
        setisLoading(false);
    }


    const submitCompleteFile = (fileid: number) => {
        setisLoading(true);
        var body = {};
        axios.put(APIURL + "/eventfilecomplete/" + fileid, body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Filen er markert som ferdig.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }
    const submitUnCompleteFile = (fileid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/eventfilecomplete/" + fileid, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const submitAssignFile = (fileid: number) => {
        setisLoading(true);
        var body = {};
        axios.put(APIURL + "/eventfileassign/" + fileid, body, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                    setSnackSuccessMessage("Fil markert som reservert.");
                    setOpenSnackSuccess(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const submitUnAssignFile = (fileid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/eventfileassign/" + fileid, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }



    const submitWipeFileArea = (areaid: number) => {
        setisLoading(true);
        axios.delete(APIURL + "/eventfileareas/" + eventfile[0].efid + "/" + areaid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfile();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoading(false);
    }

    const submitWipeLog = (logid: number) => {
        setisLoadingLogs(true);
        axios.delete(APIURL + "/eventfilelog/" + logid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfilelogs();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoadingLogs(false);
    }

    const submitWipeLogArea = (logid: number, areaid: number) => {
        setisLoadingLogs(true);
        axios.delete(APIURL + "/eventfilelogareas/" + logid + "/" + areaid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfilelogs();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoadingLogs(false);
    }

    const submitWipeLogTag = (logid: number, tagid: number) => {
        setisLoadingLogs(true);
        axios.delete(APIURL + "/eventfilelogtags/" + logid + "/" + tagid, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfilelogs();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoadingLogs(false);
    }


    const submitWipeLogUser = (logid: number, userid: number) => {
        setisLoadingLogs(true);
        axios.delete(APIURL + "/eventfileloginvestigators/" + logid + "/" + userid, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    geteventfilelogs();
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
        setisLoadingLogs(false);
    }


    // function DashFooter() {
    //     return (
    //         <Typography variant="body2" color="text.secondary" align="center">
    //             <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
    //                 <Link
    //                     underline="hover"
    //                     sx={{ display: 'flex', alignItems: 'center' }}
    //                     color="inherit"
    //                     href="/dashboard"
    //                 >
    //                     <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    //                     Forsiden
    //                 </Link>
    //                 <Link
    //                     underline="hover"
    //                     sx={{ display: 'flex', alignItems: 'center' }}
    //                     color="inherit"
    //                     href="/events"
    //                 >
    //                     <EventIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    //                     Events
    //                 </Link>
    //                 <Link
    //                     underline="hover"
    //                     sx={{ display: 'flex', alignItems: 'center' }}
    //                     color="inherit"
    //                     href={"/event/" + eventfile[0]?.efeventhash}
    //                 >
    //                     {eventfile[0]?.eventname}
    //                 </Link>
    //                 {eventfile &&
    //                     <Typography
    //                         sx={{ display: 'flex', alignItems: 'center' }}
    //                         color="text.primary"
    //                     >
    //                         {eventfile[0]?.effilename}
    //                     </Typography>
    //                 }
    //             </Breadcrumbs>
    //         </Typography>
    //     );
    // }








    useEffect(() => {
        document.title = 'Eventfile - ParaOffice';
        geteventfile();
        geteventfilelogs();
        getpousers();
        // eslint-disable-next-line
    }, []);




    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    open={openSnackSuccess}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackSuccess}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {snackSuccessMessage}
                    </Alert>
                </Snackbar>

                <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={modalViewClip}
                    onClose={handleCloseModalViewClip}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Typography sx={{ flex: 1 }}>{clipeqname} - {clipfile}</Typography>
                            <div>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            clipisvideo ?
                                                "https://media01.paraoffice.com/" + eventfile[0]?.efeventhash + "/" + cliphash
                                                :
                                                "https://media01.paraoffice.com/" + eventfile[0]?.efeventhash + "/" + cliphash
                                        )
                                    }}
                                >
                                    <ShareIcon />
                                </IconButton>
                                &nbsp;
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseModalViewClip}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {!isLoading ?
                        clipisvideo ?
                            <video width="100%" height="100%" preload='auto' controls>
                                <source src={"https://media01.paraoffice.com/" + eventfile[0]?.efeventhash + "/" + cliphash + ".mp4?" + Date.now()} type="video/mp4" />
                            </video>
                            :
                            <div>
                                <WavesurferPlayer audioFile={"https://media01.paraoffice.com/" + eventfile[0]?.efeventhash + "/" + cliphash + ".wav?" + Date.now()} />
                            </div>

                        // <SpectrumVisualizer
                        //     audio={"https://media01.paraoffice.com/" + eventfile[0]?.efeventhash + "/" + cliphash + ".wav?" + Date.now()}
                        //     theme={SpectrumVisualizerTheme.squaredBars}
                        //     colors={['#607D8B', '#607D8B']}
                        //     iconsColor="#777"
                        //     backgroundColor="black"
                        //     showMainActionIcon
                        //     showLoaderIcon
                        //     highFrequency={8000}
                        // />
                        : null
                    }
                </Dialog>




                <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={modalShareClip}
                    onClose={handleCloseModalShareClip}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Typography sx={{ flex: 1 }}>{clipeqname} - {clipfile}</Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseModalShareClip}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Box component="form" onSubmit={submitShareClip}>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    select
                                    fullWidth
                                    label="Velg discord kanal"
                                    name="dschan"
                                >
                                    <MenuItem value='ngh-felles'>ngh-felles</MenuItem>
                                    <MenuItem value='ngh-crew'>ngh-crew</MenuItem>
                                    <MenuItem value='justchatting'>justchatting</MenuItem>
                                    <MenuItem value='video-og-lydklipp'>video-og-lydklipp</MenuItem>
                                    <MenuItem value='spøkelsesprat'>spøkelsesprat</MenuItem>
                                    <MenuItem value='ghostseekers'>ghostseekers</MenuItem>
                                    <MenuItem value='lgh'>lgh</MenuItem>
                                </TextField>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <ScreenShareIcon className='pobtnicon' />
                                Del klipp
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalShareClip}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>



                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalFileAddLogUsers}
                    onClose={handleCloseLogAddUsers}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til personer på logg
                    </DialogTitle>
                    <Box component="form" onSubmit={submitFileLogAddUsers} noValidate>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    id="checkboxes-logtags"
                                    options={logUsers}
                                    disableCloseOnSelect
                                    onChange={(event, value) => setSelectedLogUsers(value)}
                                    getOptionLabel={(option) => option.efiusername}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={acicon}
                                                checkedIcon={accheckedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.efiusername}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Personer" />
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseLogAddUsers}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalFileAddLogTags}
                    onClose={handleClosemodalFileLogAddTags}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til tags på logg
                    </DialogTitle>
                    <Box component="form" onSubmit={submitFileAddTags} noValidate>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        id="checkboxes-logtags"
                                        options={logTags}
                                        disableCloseOnSelect
                                        onChange={(event, value) => setSelectedTags(value)}
                                        groupBy={(option) => option.taggroup}
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={acicon}
                                                    checkedIcon={accheckedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.label}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Tags" />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleClosemodalFileLogAddTags}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>



                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalFileAddPeople}
                    onClose={handleClosemodalFileAddPeople}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til personer i filen
                    </DialogTitle>
                    <Box component="form" onSubmit={submitFileAddPeople} noValidate>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    id="checkboxes-logtags"
                                    options={eventinvestigators}
                                    disableCloseOnSelect
                                    onChange={(event, value) => setSelectedFileUsers(value)}
                                    getOptionLabel={(option) => option.username}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={acicon}
                                                checkedIcon={accheckedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.username}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Personer" />
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleClosemodalFileAddPeople}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalFileAddContacts}
                    onClose={handleClosemodalFileAddContact}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til kontaktpersoner i filen
                    </DialogTitle>
                    <Box component="form" onSubmit={submitFileAddContacts}>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    id="checkboxes-logtags"
                                    options={contacts}
                                    disableCloseOnSelect
                                    onChange={(event, value) => setSelectedFileContacts(value)}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={acicon}
                                                checkedIcon={accheckedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Personer" />
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleClosemodalFileAddContact}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>



                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalEditFile}
                    onClose={handleModalEditFileClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Rediger informasjon om fil {eventfile[0]?.effoldername}/{eventfile[0]?.effilename}
                    </DialogTitle>
                    <Box component="form" onSubmit={submitFileEdit}>
                        <DialogContent>
                            {(pomod === "1") &&
                                <Grid item xs={12}>
                                    <Card variant="outlined" className="CardMod" sx={{ mb: 2 }}>
                                        <CardContent>
                                            <Typography gutterBottom sx={{ mb: 2 }}>
                                                Moderator alternativer
                                            </Typography>
                                            <Grid item xs={12} sx={{ mb: 2 }}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    label="Filnavn"
                                                    name="effilename"
                                                    defaultValue={eventfile[0]?.effilename}
                                                >
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} sx={{ mb: 2 }}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    label="Fillengde"
                                                    name="effilelength"
                                                    defaultValue={eventfile[0]?.effilelength}
                                                >
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} sx={{ mb: 2 }}>
                                                <Autocomplete
                                                    size="small"
                                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                                    disablePortal
                                                    id="efreviewuserid"
                                                    options={pousers}
                                                    onChange={(e, v) => handleModSelectReviewUser(e, v)}
                                                    defaultValue={revuser}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Gjennomgangsansvarlig"
                                                        name="efreviewuserid"
                                                    />}
                                                />
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            }
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Beskrivelse"
                                    name="effiledescription"
                                    defaultValue={eventfile[0]?.effiledescription}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    select
                                    fullWidth
                                    label="Opptaksansvarlig"
                                    name="efrecuser"
                                    defaultValue={eventfile[0]?.efrecuser}
                                    required
                                >
                                    <MenuItem key={"oasys"} value="0">[ingen]</MenuItem>
                                    {
                                        eventinvestigators && eventinvestigators.map(({ userid, username }: any) => {
                                            return <MenuItem key={"oa" + userid} value={userid}>{username}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid container>
                                <Grid item xs={4} sx={{ mb: 2, pr: 2 }}>
                                    <TextField
                                        size="small"
                                        type="date"
                                        fullWidth
                                        label="Dato"
                                        name="eventfiledate"
                                        defaultValue={eventfile[0]?.effilets?.slice(0, 10)}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={4} sx={{ mb: 2, pr: 2 }}>
                                    <MaskedTextField
                                        size="small"
                                        fullWidth
                                        label="Klokkeslett"
                                        name="eventfiletime"
                                        defaultValue={eventfile[0]?.effiletsdisp?.slice(11, 19)}
                                        placeholder="00:00:00"
                                        mask="99:99:99"
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{ mb: 2 }}>
                                    <TextField
                                        size="small"
                                        select
                                        fullWidth
                                        label="Nøyaktig tid"
                                        name="effiletsac"
                                        defaultValue={eventfile[0]?.effiletsac}
                                        required
                                    >
                                        <MenuItem key={"oac0"} value="0">Nei</MenuItem>
                                        <MenuItem key={"oac1"} value="1">Ja</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    select
                                    fullWidth
                                    label="Kategori"
                                    name="efcategoryid"
                                    defaultValue={eventfile[0]?.efcategoryid}
                                >
                                    {
                                        eventfilecategories && eventfilecategories?.map(({ id, label }: any) => {
                                            return <MenuItem key={"logcat" + id} value={id}>{label}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleModalEditFileClose}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalFilePublishClip}
                    onClose={handleCloseModalFileClipPublish}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Publiser klipp offentlig
                    </DialogTitle>
                    <Box component="form" onSubmit={submitPublishClip} noValidate>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Beskrivelse"
                                    name="effiledescription"
                                    value={ClipDescription}
                                    disabled
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Offentlig beskrivelse"
                                    name="efldescriptionpublic"
                                    defaultValue={ClipPublicDescription || ClipDescription}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            defaultChecked={eflclippublicstate} name="eflclippublic" />
                                    }
                                    label="Offentlig publisert"
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalFileClipPublish}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalFileLogEdit}
                    onClose={handleClosemodalFileLogEdit}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Rediger logg
                    </DialogTitle>
                    <Box component="form" onSubmit={submitFileLogEdit} noValidate>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Beskrivelse"
                                    name="efldescription"
                                    defaultValue={eventfilelogs[activelogindexid]?.efldescription}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={6} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Tid (00:00:00 format)"
                                    name="efltime"
                                    defaultValue={eventfilelogs[activelogindexid]?.efltime}
                                    placeholder="00:00:00"
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <TextField
                                    size="small"
                                    select
                                    fullWidth
                                    label="Kategori"
                                    name="efllogcategoryid"
                                    defaultValue={eventfilelogs[activelogindexid]?.efllogcategoryid}
                                >
                                    {
                                        logCategories && logCategories?.map(({ id, label }: any) => {
                                            return <MenuItem key={"logcat" + id} value={id}>{label}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleClosemodalFileLogEdit}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalFileLogClip}
                    onClose={handleClosemodalFileLogClip}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Lag klipp
                    </DialogTitle>
                    <Box component="form" onSubmit={submitNewClip}>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <MaskedTextField
                                    size="small"
                                    fullWidth
                                    label="Tidspunkt"
                                    name="eflclipts"
                                    defaultValue={eventfilelogs[activelogindexid]?.eflctliptsstart?.slice(0, 8) || eventfilelogs[activelogindexid]?.efltime}
                                    placeholder="00:00:00"
                                    mask="99:99:99"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ mb: 2 }}>
                                <TextField
                                    type="number"
                                    size="small"
                                    fullWidth
                                    label="Lengde (sekunder)"
                                    name="eflcliptslen"
                                    defaultValue={eventfilelogs[activelogindexid]?.eflcliptslen || 5}
                                    required
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" color="text.secondary" className='potypeicon' gutterBottom>
                                    Volum boost (Bruk standard 0 hvis lyd er normal)
                                </Typography>
                                <Slider
                                    aria-label="Volum boost"
                                    defaultValue={eventfilelogs[activelogindexid]?.eflclipvolume || 0}
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks
                                    min={0}
                                    max={20}
                                    name="efclipvolume"
                                    onChange={handleChangeClipVolume}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Lagre
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleClosemodalFileLogClip}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalFileAddArea}
                    onClose={handleClosemodalFileAddArea}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til områder i filen
                    </DialogTitle>
                    <Box component="form" onSubmit={submitFileAddArea} noValidate>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    id="checkboxes-logtags"
                                    options={locationareas}
                                    disableCloseOnSelect
                                    onChange={(event, value) => setSelectedFileAreas(value)}
                                    getOptionLabel={(option) => option.laname}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={acicon}
                                                checkedIcon={accheckedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.laname}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Områder" />
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleClosemodalFileAddArea}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalLinkFile}
                    onClose={handleCloseModalLinkFile}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til relatert fil
                    </DialogTitle>
                    <Box component="form" onSubmit={submitFileLink}>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    size="small"
                                    fullWidth
                                    id="checkbox-linkfiles"
                                    ListboxProps={{ style: { maxHeight: 400 } }}
                                    options={linkeventfiles}
                                    // onChange={(e, v) => handleSelectLinkFile(e, v)}
                                    onChange={(event, value) => setSelectedLinkedFiles(value)}
                                    groupBy={(option) => option.title}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={acicon}
                                                checkedIcon={accheckedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Filer" />
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModalLinkFile}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>



                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={modalFileAddLogArea}
                    onClose={handleClosemodalFileLogAddArea}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Legg til områder på logg
                    </DialogTitle>
                    <Box component="form" onSubmit={submitFileLogAddArea} noValidate>
                        <DialogContent>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    id="checkboxes-logtags"
                                    options={logAreas}
                                    disableCloseOnSelect
                                    onChange={(event, value) => setSelectedLogAreas(value)}
                                    getOptionLabel={(option) => option.laname}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={acicon}
                                                checkedIcon={accheckedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.laname}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Områder" />
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                type="submit"
                                variant="outlined"
                                color="success"
                            >
                                <SaveIcon className='pobtnicon' />
                                Legg til
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={handleClosemodalFileLogAddArea}
                            >
                                <CloseIcon className='pobtnicon' />
                                Avbryt
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>


                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1 }}>

                        {!isLoading ?
                            <Grid container spacing={2} p={2}>
                                <Grid item xs={12} md={8}>
                                    {(((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString()) && (!isComplete)) || (pomod === "1")) &&
                                        <Box component="form" onSubmit={submitNewLog} pb={3} id="newlogform">
                                            <Grid container>
                                                <Grid item xs={12} md={3} lg={2} pr={2} pb={1}>
                                                    <MaskedTextField
                                                        size="small"
                                                        fullWidth
                                                        label="Tidspunkt"
                                                        name="logts"
                                                        mask="99:99:99"
                                                        value={logtsvalue}
                                                        onChange={(e: any) => setlogtsvalue(e.target.value)}
                                                        required
                                                        autoFocus
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3} lg={2} pr={2} pb={1}>
                                                    <TextField
                                                        size="small"
                                                        select
                                                        fullWidth
                                                        label="Kategori"
                                                        name="logcategory"
                                                        required
                                                        defaultValue={4}
                                                    >
                                                        {
                                                            logCategories && logCategories?.map(({ id, label }: any) => {
                                                                return <MenuItem key={"logcat" + id} value={id}>{label}</MenuItem>
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={6} pr={2} pb={1}>
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        label="Beskrivelse"
                                                        name="logdescription"
                                                        autoComplete='off'
                                                    >
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} md={2} lg={2} pb={1}>
                                                    <Button type="submit" variant="outlined">Legg til</Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    {
                                        eventfilelogs && eventfilelogs.map(({ eflid, eflusername, eflhash, effiletypeid, effilename, efluserid, eflclippublic, eflclip, eflclipts, eflclipvolume, efllogcategoryid, efltime, efldescription, efldescriptionpublic }: any, index: number) => {
                                            var cardpub = '';
                                            if (eflclippublic === 1) { cardpub = 'CardLogPublic'; }
                                            return <Card key={eflid} className={"CardLog " + cardpub} variant="outlined" sx={{ pb: 1, mb: 1 }}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Typography variant="body1" color="text.secondary" className='potypeicon' gutterBottom>
                                                                {(efllogcategoryid === 1) && <StarIcon className='logicon loga' />}
                                                                {(efllogcategoryid === 2) && <StarIcon className='logicon logb' />}
                                                                {(efllogcategoryid === 3) && <StarIcon className='logicon logc' />}
                                                                {(efllogcategoryid === 4) && <InfoIcon className='logicon loginfo' />}
                                                                {(efllogcategoryid === 5) && <CelebrationIcon className='logicon logfun' />}
                                                                {(efllogcategoryid === 6) && <PersonAddIcon className='logicon' />}
                                                                {(efllogcategoryid === 7) && <PersonRemoveIcon className='logicon' />}
                                                                {(efllogcategoryid === 8) && <TransferWithinAStationIcon className='logicon' />}
                                                                {(efllogcategoryid === 9) && <AccessibilityIcon className='logicon' />}
                                                                {(efllogcategoryid === 10) && <PhotoCameraFrontIcon className='logicon' />}
                                                                {(efllogcategoryid === 11) && <MoodIcon className='logicon' />}
                                                                <span className='logtimebox'>{efltime}</span> {efldescription}
                                                            </Typography>
                                                            {((efluserid.toString() === pouserid?.toString() && ((eventfile[0]?.efreviewstatusid === 1)) || (pomod === "1"))) ?
                                                                <span>
                                                                    {((eflclip === 1) && (eflclipts != null)) &&
                                                                        <span>
                                                                            <IconButton color="primary" aria-label="Se klipp" onClick={e => handleOpenModalFileClipPublish(eflid, eflclippublic, efldescription, efldescriptionpublic)}>
                                                                                <PublicIcon />
                                                                            </IconButton>
                                                                            <IconButton color="primary" aria-label="Se klipp" onClick={e => handleClickOpen(eflhash, eventfile[0]?.trname, eventfile[0]?.effilename)}>
                                                                                <PlayCircleOutlineIcon />
                                                                            </IconButton>
                                                                            <IconButton color="primary" aria-label="Se klipp">
                                                                                <ShareIcon onClick={e => handleOpenModalShareClip(eflhash, effiletypeid, effilename, efldescription)} />
                                                                            </IconButton>
                                                                        </span>
                                                                    }
                                                                    <IconButton color="primary" aria-label="Lag klipp" onClick={e => handleOpenmodalFileLogClip(eflid, index)}>
                                                                        <ContentCutIcon />
                                                                    </IconButton>
                                                                    <IconButton color="primary" aria-label="Rediger" onClick={e => handleOpenmodalFileLogEdit(eflid, index)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton color="error" aria-label="Slett" onClick={e => submitWipeLog(eflid)}>
                                                                        <DeleteForever />
                                                                    </IconButton>
                                                                </span>
                                                                :
                                                                <span>
                                                                    {((eflclip === 1) && (eflclipts != null)) &&
                                                                        <span>
                                                                            <IconButton color="primary" aria-label="Se klipp" onClick={e => handleClickOpen(eflhash, eventfile[0]?.trname, eventfile[0]?.effilename)}>
                                                                                <PlayCircleOutlineIcon />
                                                                            </IconButton>
                                                                            <IconButton color="primary" aria-label="Se klipp">
                                                                                <ShareIcon onClick={e => handleOpenModalShareClip(eflhash, effiletypeid, effilename, efldescription)} />
                                                                            </IconButton>
                                                                        </span>
                                                                    }
                                                                    <IconButton color="primary" aria-label="Lag klipp" onClick={e => handleOpenmodalFileLogClip(eflid, index)}>
                                                                        <ContentCutIcon />
                                                                    </IconButton>
                                                                    <IconButton color="primary" aria-label="Rediger" disabled>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton color="error" aria-label="Slett" disabled>
                                                                        <DeleteForever />
                                                                    </IconButton>
                                                                </span>
                                                            }
                                                        </Grid>
                                                        {(eflclippublic === 1) &&
                                                            <Alert severity="info">{efldescriptionpublic}</Alert>
                                                        }
                                                        <Typography variant="body2" color="text.secondary" className='logextras' sx={{ flex: 1, flexWrap: 'wrap' }}>
                                                            &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp;
                                                            <AdminPanelSettingsIcon className='logextrasicon logicon' /><span className='logareabox'>{eflusername}</span>
                                                            {(((efluserid.toString() === pouserid?.toString()) && (!isComplete) && (eventfile[0]?.efreviewstatusid === 1)) || (pomod === "1")) ?
                                                                <HomeIcon className='logextrasicon logiconlink' onClick={e => handleOpenmodalFileLogAddArea(eflid)} />
                                                                :
                                                                <HomeIcon className='logextrasicon logicon' />
                                                            }
                                                            {eventfilelogs[index]?.areas && eventfilelogs[index].areas.map(({ laid, laname }: any, index: number) => {
                                                                return <span key={"area" + eflid + "" + laid} className='logareabox'>{laname}
                                                                    {(((efluserid.toString() === pouserid?.toString()) && (!isComplete) && (eventfile[0]?.efreviewstatusid === 1)) || (pomod === "1")) &&
                                                                        <DeleteForever className='logextrasticon text-danger' onClick={e => submitWipeLogArea(eflid, laid)} />
                                                                    }
                                                                </span>
                                                            })
                                                            }
                                                            {(((efluserid.toString() === pouserid?.toString()) && (!isComplete) && (eventfile[0]?.efreviewstatusid === 1)) || (pomod === "1")) ?
                                                                <PersonIcon className='logextrasicon logiconlink' onClick={e => handleOpenLogAddUsers(eflid)} />
                                                                :
                                                                <PersonIcon className='logextrasicon logicon' />
                                                            }
                                                            {eventfilelogs[index]?.investigators && eventfilelogs[index].investigators.map(({ userid, username }: any, index: number) => {
                                                                return <span key={"loguser" + eflid + "" + userid} className='logareabox'>{username}
                                                                    {(((efluserid.toString() === pouserid?.toString()) && (!isComplete) && (eventfile[0]?.efreviewstatusid === 1)) || (pomod === "1")) &&
                                                                        <DeleteForever className='logextrasticon text-danger' onClick={e => submitWipeLogUser(eflid, userid)} />
                                                                    }
                                                                </span>
                                                            })
                                                            }
                                                            {(((efluserid.toString() === pouserid?.toString()) && (!isComplete) && (eventfile[0]?.efreviewstatusid === 1)) || (pomod === "1")) ?
                                                                <TagIcon className='logextrasicon logiconlink' onClick={e => handleOpenmodalFileLogAddTags(eflid)} />
                                                                :
                                                                <TagIcon className='logextrasicon logicon' />
                                                            }

                                                            {eventfilelogs[index]?.tags && eventfilelogs[index].tags.map(({ ltid, ltname }: any, index: number) => {
                                                                return <span key={"logtag" + eflid + "" + ltid} className='logareabox'>#{ltname}
                                                                    {(((efluserid.toString() === pouserid?.toString()) && (!isComplete) && (eventfile[0]?.efreviewstatusid === 1)) || (pomod === "1")) &&
                                                                        <DeleteForever className='logextrasticon text-danger' onClick={e => submitWipeLogTag(eflid, ltid)} />
                                                                    }
                                                                </span>
                                                            })
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        })
                                    }


                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {(eventfile[0]?.clipsmissing != 0) &&
                                        <Alert severity="error" sx={{ mb: 3 }}>Det mangler å lages klipp på {eventfile[0]?.clipsmissing} logg(er)</Alert>
                                    }
                                    <Box className='pobox'>
                                        <Box m={2}>
                                            <Grid container>
                                                <Grid item xs={9}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <CalendarMonthIcon className='pobody1icon' /> Oppdrag
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                <Link href={"/event/" + eventfile[0]?.efeventhash} underline="hover" onClick={e => gotoEvent()}>
                                                                    {eventfile[0]?.eventname}
                                                                </Link>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <LocationOnIcon className='pobody1icon potexticred' /> Lokasjon
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                <Link href={"/location/" + eventfile[0]?.locationhash} underline="hover">
                                                                    {eventfile[0]?.locationname}
                                                                </Link>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <CameraIcon className='pobody1icon' /> Opptaker
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                {eventfile[0]?.trname}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <AspectRatioIcon className='pobody1icon' /> Oppløsning
                                                            </Typography>
                                                            {(eventfile[0]?.effiletypeid != 2) ?
                                                                <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                    {eventfile[0]?.efmetaresolution}
                                                                </Typography>
                                                                :
                                                                <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                    -
                                                                </Typography>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <InsertDriveFileIcon className='pobody1icon' /> Filnavn
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                <Link href={"https://mirage.paraoffice.com/events/" + eventfile[0]?.efeventhash + "/" + eventfiletype + "/" + eventfile[0]?.efdeviceid + "/" + eventfile[0]?.effolderhash + "/" + eventfile[0]?.effilenamehash} underline="hover">{eventfile[0]?.effilename}</Link>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <FolderIcon className='pobody1icon' /> Mappenavn
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                <Link href={"/event/" + eventfile[0]?.efeventhash} underline="hover" onClick={e => gotoEventFileFolder(eventfile[0].effolderid)}>
                                                                    {eventfile[0]?.effoldername}
                                                                </Link>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <StorageIcon className='pobody1icon' /> Størrelse
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                {DiskSize(eventfile[0]?.effilesize)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <AccessTimeIcon className='pobody1icon' /> Lengde
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                {TimeHHMMSS(eventfile[0]?.effilelength)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <UploadIcon className='pobody1icon' /> Lastet opp
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                {eventfile[0]?.efcrtsdisp} ({eventfile[0]?.efcrusername})
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <SyncAltIcon className='pobody1icon' /> Sist oppdatert
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                {eventfile[0]?.efcrtsmoddisp} ({eventfile[0]?.efcrmodusername})
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                                <FingerprintIcon className='pobody1icon' /> Fil-sjekksum
                                                            </Typography>
                                                            <Typography variant="caption" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                {eventfile[0]?.effilemd5} (MD5)<br />
                                                                {eventfile[0]?.effilesha1} (SHA1)
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {(eventfile[0]?.effiletypeid != 2) &&
                                                        <CardMedia
                                                            component="img"
                                                            image={"https://poimg01.paraoffice.com/events/" + eventfile[0]?.efeventhash + "/filethumbs/" + eventfile[0]?.efhash + ".jpg"}
                                                            alt="random"
                                                            sx={{ pr: 2 }}
                                                        />
                                                    }
                                                    <p></p>
                                                    <Button size="small" variant="outlined" href={"https://mirage.paraoffice.com/events/" + eventfile[0]?.efeventhash + "/" + eventfiletype + "/" + eventfile[0]?.efdeviceid + "/" + eventfile[0]?.effolderhash + "/" + eventfile[0]?.effilenamehash} sx={{ mb: 1 }} fullWidth><DownloadIcon className='btniconsmall' /> Last ned</Button>
                                                    {(((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString() && (!isComplete))) || (pomod === "1")) ?
                                                        <Button size="small" variant="outlined" color="warning" onClick={handleModalEditFileOpen} sx={{ mb: 1 }} fullWidth><EditIcon className='btniconsmall' /> Rediger</Button>
                                                        :
                                                        <Button size="small" variant="outlined" color="warning" disabled sx={{ mb: 1 }} fullWidth><EditIcon className='btniconsmall' /> Rediger</Button>
                                                    }
                                                    {(!eventfile[0]?.efreviewuserid) ?
                                                        <Button size="small" variant="outlined" color="warning" onClick={e => submitAssignFile(eventfile[0].efid)} sx={{ mb: 1 }} fullWidth><AccessibilityNewIcon className='btniconsmall' /> Reserver</Button>
                                                        :
                                                        ((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString()) || (pomod === "1")) ?
                                                            (eventfile[0]?.efreviewstatusid === 1) &&
                                                            <Button size="small" variant="outlined" color="warning" onClick={e => submitUnAssignFile(eventfile[0].efid)} sx={{ mb: 1 }} fullWidth><AccessibilityNewIcon className='btniconsmall' /> Fjern reservasjon</Button>
                                                            :
                                                            <Button size="small" variant="outlined" disabled sx={{ mb: 1 }} fullWidth><AccessibilityNewIcon className='btniconsmall' /> Reserver</Button>
                                                    }

                                                    {((eventfile[0]?.efreviewstatusid === 1) && ((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString()) || (pomod === "1"))) &&
                                                        ((eventfile[0]?.effiledescription) && (eventfile[0]?.effilets) && (eventfile[0].clipsmissing == 0) && (eventfile[0]?.areas.length !== 0)) &&
                                                        <Button size="small" variant="outlined" color="success" onClick={e => submitCompleteFile(eventfile[0].efid)} sx={{ mb: 1 }} fullWidth disabled={completeDisabled}><CheckIcon className='btniconsmall' /> Marker ferdig</Button>
                                                    }

                                                    {((eventfile[0]?.efreviewstatusid === 2) && ((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString()) || (pomod === "1"))) &&
                                                        <Button size="small" variant="outlined" color="error" onClick={e => submitUnCompleteFile(eventfile[0].efid)} sx={{ mb: 1 }} fullWidth><CloseIcon className='btniconsmall' /> Marker uferdig</Button>
                                                    }
                                                    {((eventfile[0]?.efreviewstatusid === 2) && ((eventfile[0]?.efreviewuserid?.toString() !== pouserid?.toString())) && (pomod !== "1")) &&
                                                        <Button size="small" variant="outlined" color="error" sx={{ mb: 1 }} fullWidth disabled><CloseIcon className='btniconsmall' /> Marker uferdig</Button>
                                                    }
                                                    {((eventfile[0]?.efreviewstatusid === 0) && (eventfile[0]?.efreviewuserid?.toString() !== null)) &&
                                                        <Button size="small" variant="outlined" color="error" sx={{ mb: 1 }} fullWidth disabled><CheckIcon className='btniconsmall' /> Marker ferdig</Button>
                                                    }


                                                </Grid>
                                                <Grid container>
                                                    {/* <Grid item xs={6}>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                            <PlaceIcon className='pobody1icon' /> Lokasjon
                                                        </Typography>
                                                        <Typography variant="caption" color="text.primary" className='potypeicon' gutterBottom>
                                                            <Link underline="hover" href={"/location/" + eventfile[0]?.locationhash}>{eventfile[0]?.eflocationname}</Link>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                            <TravelExploreIcon className='pobody1icon' /> Oppdrag
                                                        </Typography>
                                                        <Typography variant="caption" color="text.primary" className='potypeicon' gutterBottom>
                                                            <Link underline="hover" href={"/event/" + eventfile[0]?.efeventhash}>{eventfile[0]?.eventname}</Link>
                                                        </Typography>
                                                    </Grid> */}
                                                    <Grid item xs={12}>
                                                        <hr></hr>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom>
                                                            Beskrivelse
                                                        </Typography>
                                                        {eventfile[0]?.effiledescription ?
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                {eventfile[0]?.effiledescription}
                                                            </Typography>
                                                            :
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                <b className='text-danger'><WarningIcon className='pobody1icon' /></b>
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                            Kategori
                                                        </Typography>
                                                        {(eventfile[0]?.efcategoryid != 8) ?
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                {eventfile[0]?.efcategoryname}
                                                            </Typography>
                                                            :
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                <b className='text-danger'><WarningIcon className='pobody1icon' /></b>
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                            Opptaksansvarlig
                                                        </Typography>
                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                            {(eventfile[0]?.efrecusername) ?
                                                                (eventfile[0]?.efrecusername != "paraoffice") ?
                                                                    <Chip key={"u" + eventfile[0]?.efrecuserhash} avatar={<Avatar alt={eventfile[0]?.efrecusername} src={"https://poimg01.paraoffice.com/profile/" + eventfile[0]?.efrecuserhash + ".png?" + Date.now()} />} label={eventfile[0]?.efrecusername} className='pochipjoin' sx={{ mr: 1, mb: 1 }} />
                                                                    :
                                                                    <span className='pocolorinfo'>(ingen)</span>
                                                                :
                                                                <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                    <b className='text-danger'><WarningIcon className='pobody1icon' /></b>
                                                                </Typography>
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                            Reservert av
                                                        </Typography>
                                                        {(eventfile[0]?.efreviewuserid) ?
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                <Chip key={"u" + eventfile[0]?.efreviewuserid} avatar={<Avatar alt={eventfile[0]?.efreviewusername + " - " + eventfile[0]?.efreviewtsdisp} src={"https://poimg01.paraoffice.com/profile/" + eventfile[0]?.efreviewuserhash + ".png?" + Date.now()} />} label={eventfile[0]?.efreviewusername} className='pochipjoin' sx={{ mr: 1, mb: 1 }} />
                                                            </Typography>
                                                            :
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                <b className='text-danger'><WarningIcon className='pobody1icon' /></b>
                                                            </Typography>
                                                        }
                                                    </Grid>


                                                    <Grid item xs={6}>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                            Status
                                                        </Typography>
                                                        <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                            {(eventfile[0]?.efreviewstatusid === 0) &&
                                                                <Typography variant="caption" color="text.secondary" className='potypeicon pocolorinfo' gutterBottom pt={1}>
                                                                    <FiberNewIcon className='pobody1icon poiconnew' /> {eventfile[0]?.eventfilestatusname}
                                                                </Typography>
                                                            }
                                                            {(eventfile[0]?.efreviewstatusid === 1) &&
                                                                <Typography variant="caption" color="text.secondary" className='potypeicon pocolorinfo' gutterBottom pt={1}>
                                                                    <DonutSmallIcon className='pobody1icon poiconnew' /> {eventfile[0]?.eventfilestatusname}
                                                                </Typography>
                                                            }
                                                            {(eventfile[0]?.efreviewstatusid === 2) &&
                                                                <Typography variant="caption" color="text.secondary" className='potypeicon pocolorinfo' gutterBottom pt={1}>
                                                                    <CheckIcon className='pobody1icon pogreen' /> {eventfile[0]?.eventfilestatusname}
                                                                </Typography>
                                                            }
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                            Dato/Tid
                                                            {((!eventfile[0].effilets) || (pomod === "1")) && <CloudSyncIcon color="primary" onClick={submitDateSync} className='pobody1icon' sx={{ ml: 1 }} />}
                                                        </Typography>
                                                        {eventfile[0]?.effiletsdisp ?
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon pocolorinfo' gutterBottom>
                                                                {eventfile[0]?.effiletsdisp}
                                                            </Typography>
                                                            :
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                <b className='text-danger'><WarningIcon className='pobody1icon' /></b>
                                                            </Typography>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Typography variant="caption" color="text.secondary" className='potypeicon' gutterBottom pt={1}>
                                                            Nøyaktig tid
                                                        </Typography>
                                                        {(eventfile[0]?.effiletsac !== null) ?
                                                            (eventfile[0]?.effiletsac == 1) ?
                                                                <Typography variant="subtitle2" className='potypeicon pocolorinfo' gutterBottom>
                                                                    Ja
                                                                </Typography>
                                                                :
                                                                <Typography variant="subtitle2" className='potypeicon pocolorinfo' gutterBottom>
                                                                    Nei
                                                                </Typography>
                                                            :
                                                            <Typography variant="subtitle2" color="text.primary" className='potypeicon' gutterBottom>
                                                                <b className='text-danger'><WarningIcon className='pobody1icon' /> {eventfile[0]?.effiletsac} </b>
                                                            </Typography>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, mb: 1 }}>
                                                            <Typography variant="caption" color="text.secondary" className='potypeicon'>
                                                                <HomeIcon className='pobody1icon' /> Områder
                                                            </Typography>
                                                            <Typography variant="body1" color="text.secondary" className='potypeicon'>
                                                                {(((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString() && (!isComplete))) || (pomod === "1")) ?
                                                                    <AddCircleIcon color="primary" className='pocardheaderoption' onClick={handleOpenmodalFileAddArea} sx={{ mr: 1 }} />
                                                                    :
                                                                    <AddCircleIcon color="primary" className='pocardheaderoptiondisabled' sx={{ mr: 1 }} />
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Stack direction="row" spacing={1}>
                                                            {eventfile[0]?.areas && eventfile[0].areas.map(({ eflaaid, eflafid, eflaid, laname }: any, index: number) => {
                                                                return <div>
                                                                    {(((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString() && (!isComplete))) || (pomod === "1")) ?
                                                                        <Chip label={laname} className='pocolorinfo pocolorinfoborder' variant="outlined" size="small" onDelete={e => submitWipeFileArea(eflaaid)} />
                                                                        :
                                                                        <Chip label={laname} className='pocolorinfo pocolorinfoborder' variant="outlined" size="small" />
                                                                    }
                                                                </div>
                                                            })
                                                            }
                                                            {(eventfile[0]?.areas.length === 0) &&
                                                                <b className='text-danger'><WarningIcon className='pobody1icon' /></b>
                                                            }
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>

                                    <Card variant="outlined" sx={{ mb: 2 }}>
                                        <Grid container className='CardHeader'>
                                            <Grid item xs={12}>
                                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography className='potypeicon CardHeaderText'>
                                                        <AccountCircle className='pobody1icon' /> Personer involvert
                                                    </Typography>
                                                    {(((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString()) && (!isComplete)) || (pomod === "1")) ?
                                                        <AddCircleIcon color="primary" className='pocardheaderoption' onClick={handleOpenmodalFileAddPeople} sx={{ mr: 1 }} />
                                                        :
                                                        <AddCircleIcon color="primary" className='pocardheaderoptiondisabled' sx={{ mr: 1 }} />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <CardContent>
                                            {eventfile[0]?.investigators && eventfile[0].investigators.map(({ efiid, efiuid, efiuserhash, efiusername }: any, index: number) => {
                                                return <span className='pochip'>
                                                    <img src={"https://poimg01.paraoffice.com/profile/" + efiuserhash + ".png"} className='pochipimg' /> {efiusername}
                                                    {(((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString() && (!isComplete))) || (pomod === "1")) ?
                                                        <IconButton color="error" aria-label="Slett" className='pochipoption' onClick={e => submitWipeFileUser(efiuid)}>
                                                            <DeleteForever />
                                                        </IconButton>
                                                        :
                                                        <IconButton aria-label="Slett" className='pochipoptiondisabled'>
                                                            <DeleteForever />
                                                        </IconButton>
                                                    }
                                                </span>
                                            })
                                            }
                                        </CardContent>
                                    </Card>



                                    <Card variant="outlined" sx={{ mb: 2 }}>
                                        <Grid container className='CardHeader'>
                                            <Grid item xs={12}>
                                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography className='potypeicon CardHeaderText'>
                                                        <AccountCircle className='pobody1icon' /> Kontaktpersoner involvert
                                                    </Typography>
                                                    {(((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString()) && (!isComplete)) || (pomod === "1")) ?
                                                        <AddCircleIcon color="primary" className='pocardheaderoption' onClick={handleOpenmodalFileAddContact} sx={{ mr: 1 }} />
                                                        :
                                                        <AddCircleIcon color="primary" className='pocardheaderoptiondisabled' sx={{ mr: 1 }} />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <CardContent>
                                            {eventfile[0]?.contacts && eventfile[0].contacts.map(({ efcontcontactid, contactname, contacthash }: any, index: number) => {
                                                return <span className='pochip'>
                                                    <img src={"https://poimg01.paraoffice.com/contact/" + contacthash + ".png"} className='pochipimg' /> {contactname}
                                                    {(((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString() && (!isComplete))) || (pomod === "1")) ?
                                                        <IconButton color="error" aria-label="Slett" className='pochipoption' onClick={e => submitWipeFileContact(efcontcontactid)}>
                                                            <DeleteForever />
                                                        </IconButton>
                                                        :
                                                        <IconButton aria-label="Slett" className='pochipoptiondisabled'>
                                                            <DeleteForever />
                                                        </IconButton>
                                                    }
                                                </span>
                                            })
                                            }
                                        </CardContent>
                                    </Card>



                                    <Card variant="outlined">
                                        <Grid container className='CardHeader'>
                                            <Grid item xs={12}>
                                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography className='potypeicon CardHeaderText'>
                                                        <FileCopyIcon className='pobody1icon' /> Relaterte filer
                                                    </Typography>
                                                    {(((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString() && (!isComplete))) || (pomod === "1")) ?
                                                        <AddCircleIcon color="primary" className='pocardheaderoption' onClick={handleOpenModalLinkFile} sx={{ mr: 1 }} />
                                                        :
                                                        <AddCircleIcon color="primary" className='pocardheaderoptiondisabled' sx={{ mr: 1 }} />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <CardContent>
                                            {eventfile[0]?.eventfilelinks && eventfile[0].eventfilelinks.map(({ effiledescription, effilename, efhash, efid }: any, index: number) => {
                                                return <span className='pochip'>
                                                    <Link href={"/eventfile/" + efhash}> {effilename}</Link>
                                                    <Typography> - {effiledescription}</Typography>
                                                    {(((eventfile[0]?.efreviewuserid?.toString() === pouserid?.toString() && (!isComplete))) || (pomod === "1")) ?
                                                        <IconButton color="error" aria-label="Slett" className='pochipoption' onClick={e => submitFileUnLink(efid)}>
                                                            <DeleteForever />
                                                        </IconButton>
                                                        :
                                                        <IconButton aria-label="Slett" className='pochipoptiondisabled'>
                                                            <DeleteForever />
                                                        </IconButton>
                                                    }
                                                </span>
                                            })
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>

                            </Grid>
                            :
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={isLoading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        }
                    </Box>
                    {/* <Box component="footer" sx={{ p: 0, bgcolor: 'rgba(0, 0, 0, 0.3)' }}>
                        <DashFooter />
                    </Box> */}
                </Box>
            </Box>
        </ThemeProvider >
    );
}

export default EventFile;