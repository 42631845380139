import { useEffect, useState } from 'react';
import '../App.css';
import { Backdrop, Box, Breadcrumbs, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Grid, Link, ThemeProvider, Typography } from '@mui/material';
import Header from '../components/Header';
import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import disksize from '../components/DiskSize';
import moment from 'moment';

const APIURL = process.env.REACT_APP_APIURL;

const POMOD = localStorage.getItem('pomod');

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});
export const isOlderThanOneMonth = (date: moment.MomentInput) => {
    const oneMonthAgo = moment().subtract(1, 'months');
    return moment(date).isBefore(oneMonthAgo);
};

function ModEventsBackup() {
    const [isLoading, setisLoading] = useState(true);
    const [events, setEvents] = useState<any[]>([]);

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    if (POMOD !== "1") {
        window.location.assign("/dashboard");
    }


    useEffect(() => {
        document.title = 'ParaOffice';
        axios.get(APIURL + "/mod/eventsbackup", { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisLoading(false);
                if (response.status === 200) {
                    setisLoading(false);
                    setEvents(response.data);
                    console.log(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
            });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Header onDrawerToggle={handleDrawerToggle} />
            <CssBaseline />
            <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/dashboard"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Forsiden
                </Link>
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                >
                    MOD
                </Typography>
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                >
                    Events backup
                </Typography>
            </Breadcrumbs>
            {!isLoading ?
                <main>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Box m={2}>
                                <Typography variant="body2" color="text.secondary" gutterBottom className='potypeicon'>
                                    <EventIcon className='potexticonxl' /> Events backup
                                </Typography>
                                {events.map(({ eventid, eventhash, eventname, totalfilesize, eventstoragename, eventdatedisp, eventstorageidb1, eventstorageidb2, eventstorageidb3, eventstorageidb4, eventstorageidb5, eventstorageidb1ts, eventstorageidb2ts, eventstorageidb3ts, eventstorageidb4ts, eventstorageidb5ts, eventstorageidb1name, eventstorageidb2name, eventstorageidb3name, eventstorageidb4name, eventstorageidb5name, eventstorageidb1disk, eventstorageidb2disk, eventstorageidb3disk, eventstorageidb4disk, eventstorageidb5disk }: any, index: number) => {
                                    return <Card variant="outlined" key={eventid} sx={{ mb: 1 }}>
                                        <CardContent>
                                            <Grid container width='100%'>
                                                <Grid item ml={2} mr={2} sx={{ flex: 1 }}>
                                                    <Link href={"/event/" + eventhash}>#{eventid} ({eventdatedisp}) - {eventname} - {disksize(totalfilesize)}</Link> - {eventhash}
                                                    <Typography
                                                        variant="body2"
                                                        color={eventstoragename !== "mirage" ? "error" : "success"}
                                                        gutterBottom
                                                    >
                                                        {eventstoragename}
                                                    </Typography>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                Backup #1: {eventstorageidb1name} - {eventstorageidb1disk}
                                                            </Typography>
                                                            {(eventstorageidb1ts === null) ?
                                                                <Typography variant="body2" sx={{ color: '#D84315' }}>
                                                                    IKKE VERIFISERT
                                                                </Typography>
                                                                :
                                                                (isOlderThanOneMonth(eventstorageidb1ts)) ?
                                                                    <Typography variant="body2" sx={{ color: '#2E7D32' }}>
                                                                        {eventstorageidb1ts}
                                                                    </Typography>
                                                                    :
                                                                    <Typography variant="body2" sx={{ color: 'orange' }}>
                                                                        {eventstorageidb1ts}
                                                                    </Typography>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                Backup #2: {eventstorageidb2name} - {eventstorageidb2disk}
                                                            </Typography>
                                                            {(eventstorageidb2ts === null) ?
                                                                <Typography variant="body2" sx={{ color: '#D84315' }}>
                                                                    IKKE VERIFISERT
                                                                </Typography>
                                                                :
                                                                <Typography variant="body2" sx={{ color: '#2E7D32' }}>
                                                                    {eventstorageidb2ts}
                                                                </Typography>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                })
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </main>
                :
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </ThemeProvider>
    );
}

export default ModEventsBackup;